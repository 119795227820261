module.exports = {
	title: "ROC management portal",
	serverOptions: [
		{
			label: "DEV",
			id: "dev",
			channel: "roc",
			docsVersion: "develop",
			hostname: "prod.faulpelz.io",
			accessCookieConfig: {
				name: "glient2idroc",
				path: "/glient2/roc",
				domain: ".prod.faulpelz.io",
			},
			refreshCookieConfig: {
				name: "refreshroc",
				path: "/api/oauth/0e19a68ec8750be0bbf969a35937663b/redirect/roc",
				domain: ".prod.faulpelz.io",
			},
			glientWsUrl: "wss://api-client.prod.faulpelz.io/glient2/roc",
			gupportWsUrl: "wss://api-mgmt.prod.faulpelz.io/gupport2/roc",
			ccWsUrl: "wss://api-mgmt.prod.faulpelz.io/cc2/roc",
			redirectUrl: "https://prod.faulpelz.io/api/oauth/0e19a68ec8750be0bbf969a35937663b/redirect/roc/redirect",
			revokeUrl: "https://prod.faulpelz.io/api/oauth/0e19a68ec8750be0bbf969a35937663b/redirect/roc/logout",
			invalidateTokenUrl: "https://prod.faulpelz.io/api/oauth/0e19a68ec8750be0bbf969a35937663b/redirect/roc",
			editAccountUrl: "https://prod.faulpelz.io/api/profile/0e19a68ec8750be0bbf969a35937663b/redirect",
		},
		{
			label: "ALPHA",
			id: "alpha",
			channel: "roc",
			docsVersion: "develop",
			hostname: "eu.roc-connect.com",
			accessCookieConfig: {
				name: "glient2idroc",
				path: "/glient2/roc",
				domain: ".eu.roc-connect.com",
			},
			refreshCookieConfig: {
				name: "refreshroc",
				path: "/api/oauth/0e19a68ec8750be0bbf969a35937663b/redirect/roc",
				domain: ".eu.roc-connect.com",
			},
			glientWsUrl: "wss://api-client.eu.roc-connect.com/glient2/roc",
			gupportWsUrl: "wss://api-mgmt.eu.roc-connect.com/gupport2/roc",
			ccWsUrl: "wss://api-mgmt.eu.roc-connect.com/cc2/roc",
			redirectUrl: "https://eu.roc-connect.com/api/oauth/0e19a68ec8750be0bbf969a35937663b/redirect/roc/redirect",
			revokeUrl: "https://eu.roc-connect.com/api/oauth/0e19a68ec8750be0bbf969a35937663b/redirect/roc/logout",
			invalidateTokenUrl: "https://eu.roc-connect.com/api/oauth/0e19a68ec8750be0bbf969a35937663b/redirect/roc",
			editAccountUrl: "https://eu.roc-connect.com/api/profile/0e19a68ec8750be0bbf969a35937663b/redirect",
		},
		{
			label: "ROC",
			id: "prod",
			channel: "roc",
			docsVersion: "public",
			hostname: "us.roc-connect.com",
			accessCookieConfig: {
				name: "glient2idroc",
				path: "/glient2/roc",
				domain: ".us.roc-connect.com",
			},
			refreshCookieConfig: {
				name: "refreshroc",
				path: "/api/oauth/0e19a68ec8750be0bbf969a35937663b/redirect/roc",
				domain: ".us.roc-connect.com",
			},
			glientWsUrl: "wss://api-client.us.roc-connect.com/glient2/roc",
			gupportWsUrl: "wss://api-mgmt.us.roc-connect.com/gupport2/roc",
			ccWsUrl: "wss://api-mgmt.us.roc-connect.com/cc2/roc",
			redirectUrl: "https://us.roc-connect.com/api/oauth/0e19a68ec8750be0bbf969a35937663b/redirect/roc/redirect",
			revokeUrl: "https://us.roc-connect.com/api/oauth/0e19a68ec8750be0bbf969a35937663b/redirect/roc/logout",
			invalidateTokenUrl: "https://us.roc-connect.com/api/oauth/0e19a68ec8750be0bbf969a35937663b/redirect/roc",
			editAccountUrl: "https://us.roc-connect.com/api/profile/0e19a68ec8750be0bbf969a35937663b/redirect",
		},
	],
};
// {
// 	glientWsUrl: "wss://factoryleader.roc-connect.com/glient25/roc",
// 	gupportWsUrl: "wss://factoryleader.roc-connect.com/gupport2/roc",
// 	ccWsUrl: "wss://factoryleader.roc-connect.com/cc2/roc",
// 	redirectUrl: "https://factoryleader.roc-connect.com/api/oauth/0e19a68ec8750be0bbf969a35937663b/redirect/roc/redirect",
// 	revokeUrl: "https://factoryleader.roc-connect.com/api/oauth/0e19a68ec8750be0bbf969a35937663b/redirect/roc/logout",
// 	invalidateTokenUrl: "https://factoryleader.roc-connect.com/api/oauth/0e19a68ec8750be0bbf969a35937663b/redirect/roc",
// 	editAccountUrl: "https://factoryleader.roc-connect.com/api/profile/0e19a68ec8750be0bbf969a35937663b/redirect",
// }
